import React from "react"
import "./Court.css"
import Slot from "./Slot"

const Court = (props) => {
  const reserveClicked = (slotId) => {
    props.reserveClicked(props.courtId, props.scheduleId, slotId)
  }

  const cancelClicked = () => {
    props.cancelClicked()
  }

  const submitClicked = (data) => {
    props.submitClicked(data)
  }

  return (
    <div className="Court">
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="text-nowrap">{props.label}</h5>
        <div className="text-muted">{props.date}</div>
      </div>
      <table className="table table-striped table-bordered">
        <tbody>
          {props.slots.map((slot) => {
            var isActive =
              props.activeBooking &&
              props.activeBooking.courtId === props.courtId &&
              props.activeBooking.scheduleId === props.scheduleId &&
              props.activeBooking.slotId === slot.id
            return (
              <Slot
                {...slot}
                date={props.date}
                clickReserve={reserveClicked}
                cancelReserve={cancelClicked}
                submitReserve={submitClicked}
                onDelete={props.onDelete}
                isBooking={isActive}
                errorMessage={
                  isActive ? props.activeBooking.errorMessage : null
                }
                inFlight={isActive && props.activeBooking.inFlight}
                key={slot.id}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Court
