import React from "react"
import "./App.css"
import Layout from "./components/Layout"
import Reservations from "./components/Reservations"

function App() {
  return (
    <Layout>
      <div className="bg-transparent text-dark p-2">
        Please select a date on the left to show available time slots, then
        click on Reserve to book particular court and time. Booking confirmation
        and instructions how to cancel your reservation will be sent to your
        email.
      </div>
      <Reservations />
    </Layout>
  )
}

export default App
