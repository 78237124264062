import React, { Component } from 'react';
import Booking from './Booking';
import { t } from 'typy';
import moment from 'moment';

class Slot extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            accessCode: '',
        };
    }

    componentDidUpdate() {
        // clean form data if not booking
        if (!this.props.isBooking && this.state.email.length) {
            this.setState({email: '', accessCode: ''});
        }
    }

    getCutOffTime = () => {
        return moment(this.props.date + ' ' + this.props.cutOffTime, 'YYYY-MM-DD H:mmA');
    }

    // return if slot is reservable or it is too late to reserve (i.e. the event time is in past)
    canReserveNow = () => {
        // moderated slots can be booked at any time
        if (t(this.props, 'isModerated').safeBoolean) {
            return true;
        }

        // otherwise return true if before cut-off time
        return this.getCutOffTime().unix() > moment().unix();
    }

    reserveCancel = () => {
        this.setState({email: '', accessCode: ''});
        this.props.cancelReserve();
    }

    reserveSubmit = () => {
        this.props.submitReserve({email: this.state.email, accessCode: this.state.accessCode});
    }

    onEmailChange = value => {
        this.setState({email: value.trim()});
    }

    renderReservations = () => {
        return this.props.reservations.map(reservation => (
            <Booking
                name={reservation.name}
                id={reservation.id}
                key={reservation.id}
                isCancellable={this.canReserveNow()}
                onDelete={this.props.onDelete}
            />)
        );
    }

    renderInfo = () => {
        const organizerName  = t(this.props, 'organizer.name').safeString;
        const organizerEmail = t(this.props, 'organizer.email').safeString;
        const isModerated    = t(this.props, 'isModerated').safeBoolean;
        const info           = t(this.props, 'info').safeString;

        if (!isModerated && !info && !organizerName) {
            return null;
        }
        return (
            <div className="alert alert-info">
                { isModerated && <div className="font-weight-bold">This is a moderated group.</div> }
                { info && <div className="my-1" dangerouslySetInnerHTML={{__html: info}} /> }
                { organizerName && <div className="">
                    <span className="font-weight-bold">Organizer:</span> {organizerName}
                </div> }
                { organizerEmail && <div className="">
                    <span className="font-weight-bold">Email:</span> <a href={'mailto:' + organizerEmail} target="_blank" rel="noopener noreferrer">{organizerEmail}</a>
                </div> }
            </div>
        )
    }

    renderAccessCode = () => {
        const isModerated = t(this.props, 'isModerated').safeBoolean;
        if (isModerated) {
            return (
                <div>
                    <input className="form-control" placeholder="Access code" value={this.state.accessCode} onChange={e => this.setState({accessCode: e.target.value}) }/>
                    <small className="form-text text-muted">Please provide moderator access code.</small>
                </div>
            );
        }
        return null;
    }

    renderActions = () => {
        const submitDisabled    = this.state.email.length === 0;
        const maxPlayers        = parseInt(this.props.maxPlayers, 10);
        const availableCapacity = Math.max(0, maxPlayers - this.props.reservations.length);
        const isModerated       = t(this.props, 'isModerated').safeBoolean;
        const isReserved        = t(this.props, 'isReserved').safeBoolean;
        const reserveLabel      = 'Reserve' + (isModerated ? ' (Moderator only)' : '');

        if (this.props.isBooking) {
            return (
                <div>
                    {this.props.errorMessage && <div className="alert alert-danger">{this.props.errorMessage}</div>}
                    <input className="form-control" placeholder="Your STC email" value={this.state.email} onChange={e => this.onEmailChange(e.target.value)} />
                    { this.renderAccessCode() }
                    <div className="mt-2 text-right">
                        { this.props.inFlight && <span className="font-weight-bold pr-1">Please wait...</span> }
                        <button className="btn btn-secondary mr-2" onClick={this.reserveCancel} disabled={this.props.inFlight}>Cancel</button>
                        <button className="btn btn-primary" onClick={this.reserveSubmit} disabled={submitDisabled || this.props.inFlight}>Submit</button>
                    </div>
                </div>
            );
        }

        if (isReserved) {
            return <div className="text-muted text-center">Reservation is not available for this time.</div>
        }

        if (!this.canReserveNow()) {
            return null;
        }

        return (
            <div className="d-flex align-items-center justify-content-between">
                {availableCapacity > 0
                    ? <button className="btn btn-success" onClick={() => this.props.clickReserve(this.props.id)}>{reserveLabel}</button>
                    : <div />
                }
                <div className="text-muted" style={{fontSize: '.85rem'}}>Available: <span className="font-weight-bold">{availableCapacity}</span>/{maxPlayers}</div>
            </div>
        );
    }

    render () {
        return (
            <tr>
                <td style={{width:'150px'}}>
                    <div className={'text-nowrap' + (this.props.isBooking ? ' font-weight-bold' : '')}>{this.props.label}</div>
                    {this.props.subLabel && <div className="mt-1 badge badge-warning">{this.props.subLabel}</div>}
                </td>
                <td>
                    {this.renderInfo()}
                    <div className="mb-2">{this.renderReservations()}</div>
                    {this.renderActions()}
                </td>
            </tr>
        );
    }
}

export default Slot;