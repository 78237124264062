const BallMachineInfo = ({ data }) => {
  return (
    <div>
      <div className="p-2 alert alert-info">
        <strong>Using Ball Machine:</strong> Please book a court as usual -
        first add your name, then in the same time slot add one of the{" "}
        <strong>ball machine</strong> emails (listed below). Use your booking
        code for cancelling the ball machine `player`.
        <br />
        Any questions or issues, please contact{" "}
        <a href="mailto:ball_machine@saanichtennisclub.org">
          ball_machine@saanichtennisclub.org
        </a>
        .
      </div>
      <div>
        <table cellPadding={3}>
          <tr>
            {data.map((bm, index) => (
              <td>
                <strong>{`${bm.first_name} ${bm.last_name}`}:</strong>&nbsp;
                {bm.email ? (
                  <code>{bm.email}</code>
                ) : (
                  <span className="text-muted">N/A</span>
                )}
              </td>
            ))}
          </tr>
        </table>
      </div>
    </div>
  )
}

export default BallMachineInfo
