import React from 'react';
import './DateMenu.css'

const DateMenu = (props) => {
    return (
        <div className="DateMenu">
            <h5>Select Date</h5>
            <div className="list-group">
                {
                    props.dates.map((dateProps, key) => {
                    return (
                        <button
                            type="button"
                            className={"list-group-item list-group-item-action text-nowrap" + (dateProps.date === props.activeDate ? ' active' : '')}
                            onClick={() => props.selectDate(dateProps.date)}
                            key={key}
                            >{dateProps.label}</button>
                    )})
                }
            </div>
        </div>
    )
}

export default DateMenu;