import React, { Component } from 'react';
import axios from '../axios';

class Booking extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            inDelete: false,
            accessCode: '',
            errorMessage: null,
            inFlight: false
        };
    }

    deleteClicked = (e) => {
        e.preventDefault();
        this.setState({inDelete: true});
    }

    deleteCancel = () => {
        this.setState({accessCode: '', errorMessage: null, inDelete: false});
    }

    deleteSubmit = () => {
        const data = {
            reservationId: this.props.id,
            accessCode   : this.state.accessCode
        };
        this.setState({inFlight: true});
        axios.post('/delete', data)
            .then(response => {
                if (response.data.success) {
                    return this.props.onDelete(this.props.id);
                }
                if (!response.data.success) {
                    this.setState({errorMessage: response.data.message});
                }
            })
            .catch(error => {console.log('error', error)})
            .then(() => {
                this.setState({inFlight: false});
            });
    }

    renderDelete() {
        if (!this.state.inDelete) {
            return null;
        }
        const submitDisabled = this.state.accessCode.length === 0 || this.state.inFlight;
        return (
            <div className="mb-2">
                {this.state.errorMessage && <div className="alert alert-danger">{this.state.errorMessage}</div>}
                <input className="form-control" placeholder="Cancellation Code" value={this.state.accessCode} onChange={e => this.setState({accessCode: e.target.value}) }/>
                <small className="form-text text-muted">Code has been emailed to you in confirmation email.</small>
                <div className="mt-2 text-right">
                    { this.state.inFlight && <span className="font-weight-bold pr-1">Please wait...</span> }
                    <button className="btn btn-secondary mr-2" onClick={this.deleteCancel} disabled={this.state.inFlight}>Close</button>
                    <button className="btn btn-danger" onClick={this.deleteSubmit} disabled={submitDisabled}>Remove Reservation</button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="Booking">
                <span className="font-weight-bold pr-2">{this.props.name} {this.state.inDelete && <span>- request cancellation</span>}</span>
                { this.renderDelete() }
                { !this.state.inDelete && this.props.isCancellable && <button className="btn btn-sm text-danger" onClick={this.deleteClicked}>[Cancel]</button> }
            </div>
        )
    }
}

export default Booking;