import React, { Component } from "react"
import axios from "../axios"
import Court from "./Court"
import DateMenu from "./DateMenu"
import "./Reservations.css"
import BallMachineInfo from "./BallMachineInfo"

class Reservations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      courts: [],
      dates: [],
      ballMachines: [],
      inBooking: null, // if active, contains these props: courtId, scheduleId, slotId, inFlight, errorMessage
      activeDate: null,
    }
  }

  componentDidMount() {
    this.fetchCourts().then(() => {
      // if date is passed in query set it as active date if it falls within showed date range
      if (window.location.search.length > 0) {
        let fragments = window.location.search.split("?date=")
        if (fragments.length === 2) {
          var date = fragments[1]
          if (this.state.dates.map((dateObj) => dateObj.date).includes(date)) {
            this.fetchCourts(date)
          }
        }
      }
    })
  }

  fetchCourts = (date) => {
    return axios
      .get("/reservations" + (date ? `?date=${date}` : ""))
      .then((response) => {
        this.setState({
          courts: response.data.courts,
          ballMachines: response.data.ball_machines,
          activeDate: response.data.date,
          dates: response.data.dates,
          inBooking: null,
        })
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  reserveHandler = (courtId, scheduleId, slotId) => {
    this.setState({ inBooking: { courtId, scheduleId, slotId } })
  }

  cancelHandler = () => {
    this.setState({ inBooking: null })
  }

  submitHandler = (formData) => {
    const data = {
      email: formData.email,
      accessCode: formData.accessCode,
      date: this.state.activeDate,
      courtId: this.state.inBooking.courtId,
      scheduleId: this.state.inBooking.scheduleId,
      slotId: this.state.inBooking.slotId,
    }
    this.setState({ inBooking: { ...this.state.inBooking, inFlight: true } })
    axios
      .post("/reserve", data)
      .then((response) => {
        if (response.data.success) {
          return this.fetchCourts(this.state.activeDate)
        }
        if (response.data.message) {
          return response.data.message
        }
      })
      .then((errorMessage) => {
        this.setState({
          inBooking: { ...this.state.inBooking, inFlight: false, errorMessage },
        })
      })
      .catch((error) => {
        console.log("error", error)
      })
  }

  onDeleteHandler = (reservationId) => {
    this.fetchCourts(this.state.activeDate)
  }

  selectDateHandler = (date) => {
    this.fetchCourts(date)
  }

  dismissOverlay = () => {
    this.setState({ inBooking: null })
  }

  render() {
    var courts = this.state.courts.map((court) => {
      return (
        <Court
          {...court}
          date={this.state.activeDate}
          reserveClicked={this.reserveHandler}
          cancelClicked={this.cancelHandler}
          submitClicked={this.submitHandler}
          onDelete={this.onDeleteHandler}
          activeBooking={this.state.inBooking}
          key={`${court.courtId}-${court.scheduleId}`}
        />
      )
    })
    return (
      <div>
        <BallMachineInfo data={this.state.ballMachines} />
        <hr />
        <div className="Reservations d-md-flex">
          <DateMenu
            activeDate={this.state.activeDate}
            dates={this.state.dates}
            selectDate={this.selectDateHandler}
          />
          <div className="d-flex flex-wrap">{courts}</div>
          {this.state.courts.length === 0 && (
            <div className="alert alert-danger align-self-start font-weight-bold">
              No courts available for this date.
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Reservations
